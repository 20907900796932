<template>
  <div
    v-if="messages && messages.length > 0"
    class="row over-content"
  >
    <div class="fourteen wide column">
      <ul
        class="message-list"
        aria-live="assertive"
      >
        <MessageInfo
          v-for="message in messages"
          :key="'message-' + message.counter"
          :message="message"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MessageInfo from '@/components/MessageInfo';

export default {
  name: 'MessageInfoList',
  
  components: {
    MessageInfo,
  },
  computed: {
    ...mapState(['messages']),
  },
};
</script>

<style scoped>

.row.over-content {
  position: absolute; /* to display message info over page content */
  z-index: 99;
  opacity: 0.95;
  width: calc(100% - 4em); /* 4em is #content left + right paddings */
  top: calc(61px + 1em); /* 61px is #app-header height */
  right: 2em; /* 2em is #content left paddings */
}
.message-list{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
}
</style>